.services-page {
    max-width: 1280px;
    display: flex;
    flex-direction: column;
    padding: 20px 5px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.6);
    border-radius: 15px;
    border-top: 1px solid rgba(95, 95, 95, 0.6);
    text-align: left;
    margin: 10px 5px;
}

.services-page-title {
    font-size: 2rem;
    font-weight: 600;
}

.services-content {
    display: flex;
    column-gap: 2rem;
    flex-wrap: wrap;
}

@media (width >=410px) {
    .services-content-column {
        display: flex;
        flex-direction: column;
        min-width: 410px;
    }
}

@media (width <410px) {
    .services-content-column {
        display: flex;
        flex-direction: column;
        min-width: 300px;
    }
}

.services-main-item {
    margin-top: 30px;
    font-size: 26px;
}

.services-subitem {
    font-size: 22px;
}