.contact-page {
    max-width: 1280px;
    display: flex;
    flex-direction: column;
    padding: 20px 5px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.6);
    border-radius: 15px;
    border-top: 1px solid rgba(95, 95, 95, 0.6);
    text-align: left;
    margin: 10px 5px;
}

.contact-title {
    font-size: 2rem;
    font-weight: 600;
}

.contact-panel {
    display: flex;
    column-gap: 4rem;
    flex-wrap: wrap;
    margin-top: 2rem;
}

.contact-description {
    display: flex;
    flex-direction: column;
    font-size: 26px;
    font-family: 'Overlock';
}

.contact-address {
    margin-top: 10px;
    font-size: 20px;
    font-family: 'Open Sans';
}

.contact-us {
    font-size: 2rem;
    color: rgb(0, 123, 154);
    text-align: center;
}

.contact-map {
    margin-top: 2rem;
}