.App {
  text-align: -webkit-center;
  font-family: 'Open Sans';
}

.App-header-panel {
  background-color: rgb(0, 185, 232);
  width: calc(100% -10px);
}

.App-header {
  max-width: 1280px;
  width: 100%;
  font-family: 'Overlock';
  display: flex;
  flex-wrap: wrap;
  align-content: center;
}

@media (width >680px) {
  .App-header-height {
    height: 300px;
  }
}

@media (width <600px) {
  .App-header-height {
    margin-top: 60px;
  }
}

.App-header-text {
  flex: 1 1 0px;
  color: white;
  font-size: 45px;
  min-width: 320px;
  font-weight: 500;
  display: flex;
  flex-direction: column;
}

.App-header-contact-panel {
  flex: 1 1 0px;
  font-size: 36px;
  color: white;
  min-width: 300px;
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 10px;
}

@media (width <400px) {
  .App-header-contact-panel-size {
    font-size: 26px;
  }
}

.App-header-contact {
  display: flex;
  flex-direction: row;
}

.App-header-image {
  width: 100px;
  align-self: center;
}

.App-body {
  background-color: white;
}

.App-footer {
  position: relative;
  width: 100%;
  align-content: center;
  background: white;
  border-top: 3px solid rgb(0, 185, 232);
}

.App-footer-panel {
  max-width: 1280px;
  margin-top: 1rem;
  display: flex;
  flex-wrap: wrap;
  column-gap: 25px;
  justify-content: center;
}

.App-footer-item {
  display: flex;
  flex-direction: column;

  text-align: left;
  padding: 0px 5px;
}

@media (width <410px) {
  .App-footer-item-size {
    min-width: 320px;
  }
}

@media (width >=410px) {
  .App-footer-item-size {
    min-width: 400px;
  }
}

.App-footer-headline {
  font-size: 26px;
  color: rgb(0, 123, 154);
  font-weight: 600;
}

.App-footer-image {
  max-width: 110px;
}

.App-footer-copyright {
  margin-top: 2rem;
  margin-bottom: 20px;
  font-size: 13px;
}

@media (width >=410px) {
  .App-footer-map {
    width: 410px;
    height: 250px;
  }
}

@media (width <400px) {
  .App-footer-map {
    width: 310px;
    height: 250px;
  }
}