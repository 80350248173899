.about-page {
    max-width: 1280px;
    display: flex;
    flex-direction: column;
    padding: 20px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.6);
    border-radius: 15px;
    border-top: 1px solid rgba(95, 95, 95, 0.6);
    text-align: left;
    margin: 10px;
}

.about-page-title {
    font-size: 2rem;
    font-weight: 600;
}

.about-page-description {
    margin-top: 20px;
    font-size: 1.25rem;
}

.about-page-details-panel {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    column-gap: 2rem;
}

.about-page-image {
    width: 180px;
}

.about-page-details{
    margin-top: 20px;
}

.about-page-details-title {
    font-size: 26px;
    font-family: 'Overlock';
}

.about-page-list-item {
    margin-bottom: 10px;
    font-size: 1.25rem;
}