.home-panel {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 2rem;
}

.home-image-panel {
    position: relative;
    text-align: center;
    max-width: 1280px;
}

.home-image {
    max-width: 100%;
    height: auto;
}

.home-image-text {
    position: absolute;
    right: 5px;
    top: 25%;
    color: rgb(0, 123, 154);
    width: 30%;
    margin-right: 10px;
}

@media (width >670px) {
    .home-image-text-size {
        font-size: 42px;
    }
}

@media (width <=670px) {
    .home-image-text-size {
        font-size: 19px;
    }
}

.home-image-link {
    position: absolute;
    bottom: -3px;
    left: 50%;
    transform: translate(-50%, 0);
}

@media (width <=670px) {
    .home-image-link-size {
        font-size: 0.75rem;
        width: 245px;
    }
}

.home-overview-panel {
    display: flex;
    column-gap: 25px;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
}

.home-overview-panel-card {
    margin-top: 2rem;
}

.home-overview-card-title {
    font-size: 26px;
    color: rgb(0, 123, 154);
    font-weight: 600;
}

.home-overview-card-description {
    text-align: left;
    line-height: 1.6em;
    margin: 0px 1rem;
}

.home-overview-card-description-review {
    color: rgb(0, 123, 154);
    font-style: italic;
    font-size: 23px;
}

.home-overview-card-list-item {
    margin-bottom: 12px;
}

.home-overview-card-button {
    text-align: right;
    padding-bottom: 1rem;
}