.reviews-page{
    max-width: 1280px;
    display: flex;
    flex-direction: column;
    padding: 20px 10px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.6);
    border-radius: 15px;
    border-top: 1px solid rgba(95, 95, 95, 0.6);
    text-align: left;
    margin: 10px 5px;
}

.reviews-title {
    font-size: 2rem;
    font-weight: 600;
}

.reviews-description {
    margin-top: 20px;
    font-size: 1.25rem;
}

.reviews-panel {
    display: flex;
    flex-direction: column;    
}

.review {
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.6);
    border-radius: 15px;
    border-top: 1px solid rgba(95, 95, 95, 0.6);
    margin-top: 25px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    background: rgb(233, 250, 255);
}

.review-image {
    max-width: 180px;
    max-height: 180px;
}

.review-text-panel {
    display: flex;
    flex-direction: column;
    align-items: left;
    margin-left: 1rem;
    min-width: 200px;
    max-width: 1000px;
}

.reviewer-name {
    color: rgb(0, 123, 154);
    font-size: 2rem;
    margin-top: 10px;
}

.review-text {
    margin-top: 10px;
    font-size: 1.25rem;
    margin-right: 10px;
}

.review-image-source {
    margin-top: 5px;
    text-align: center;
}